/*
0-600px: Phone
600 - 900px: Tablet portrait
900-1200px: Tablet landscape
[1200-1800] is where our normal styles apply
1800px +: Big screen
*/
/* @mixin respond-phone {
    @media (max-width: 600px) {@content};
} */
/*
$breakpoint arguement choices:
- phone
-tab-port
-tab-land
-big-desktop

ORDER: Base + tipography > general layout + grid > page layout > components

1em = 16px;
*/
/**
 * Colors
 */
/**
 * Font Sizes
 */
/**
 * Spacing
 */
/**
 * Grid Variables
 */
* {
  box-sizing: content-box; }

body {
  color: #313131;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.5;
  margin: 0 auto;
  background: #F0F6F7; }

hr,
.divider {
  border: none;
  margin: 10px 0 30px;
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%; }

ul {
  padding-left: 0; }

div,
label,
span,
p,
li,
h1,
h2,
h3,
h4,
h5,
h6,
legend,
fieldset {
  font-family: "Source Sans Pro", sans-serif !important; }

button:disabled {
  cursor: default; }

.MuiGrid-spacing-xs-3 {
  width: 100% !important; }

.is-active {
  font-weight: bold; }

.react-select__control .MuiInputBase-input.MuiOutlinedInput-input {
  background-color: white;
  display: flex; }
  .react-select__control .MuiInputBase-input.MuiOutlinedInput-input span[class*="indicatorSeparator"] {
    height: 100%;
    margin: 0; }
  .react-select__control .MuiInputBase-input.MuiOutlinedInput-input div[class*="IndicatorsContainer"] > div:last-of-type svg {
    fill: #008cb6; }
  .react-select__control .MuiInputBase-input.MuiOutlinedInput-input div[class*="indicatorContainer"] {
    cursor: pointer; }
  .react-select__control .MuiInputBase-input.MuiOutlinedInput-input > div {
    flex-wrap: nowrap; }

.react-select__control + div[class*="menu"] {
  background-color: white;
  z-index: 99; }

input {
  font-family: "Source Sans Pro", sans-serif !important;
  box-shadow: none; }
  input[type="text"] {
    background-color: white; }
  input:focus {
    border-bottom: none; }

.MuiOutlinedInput-adornedEnd {
  background-color: white; }
  .MuiOutlinedInput-adornedEnd button[type="button"] {
    background-color: rgba(255, 255, 255, 0);
    padding: 0;
    border: none; }
    .MuiOutlinedInput-adornedEnd button[type="button"] svg path:first-child {
      fill: #008cb6; }
    .MuiOutlinedInput-adornedEnd button[type="button"]:hover, .MuiOutlinedInput-adornedEnd button[type="button"]:focus {
      background-color: rgba(255, 255, 255, 0); }

div.form-group {
  margin-bottom: 30px; }

.main-progress {
  display: block;
  position: absolute;
  width: 120px;
  height: 120px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: none !important; }

.ReactTable .rt-thead.-filters .rt-th select,
.pagination-bottom .-pagination .select-wrap select {
  background-color: white;
  color: #777;
  border-radius: 8px;
  line-height: 18px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0) 50%, #008cb6 50%), linear-gradient(135deg, #008cb6 50%, rgba(255, 255, 255, 0) 50%);
  background-position: calc(100% - 15px) calc(1em - 1px), calc(100% - 10px) calc(1em - 1px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: #adadad;
  padding-right: 20px; }

.disorders-list {
  column-count: 2;
  width: 100%; }
  @media screen and (min-width: 600px) {
    .disorders-list {
      column-count: 3; } }

.sms-field-wrapper > div {
  width: 100%;
  max-width: 410px; }
  .sms-field-wrapper > div > div {
    padding: 0; }

.sms-field-wrapper fieldset {
  padding: 0 !important;
  border-radius: 20px 20px 20px 0px; }

.sms-field-wrapper textarea {
  width: 100%;
  font-family: 'Courier New', Courier, monospace;
  background-color: white;
  border-radius: 20px 20px 20px 0px;
  padding: 20px; }

@media screen and (min-width: 960px) {
  .sms-field-wrapper + .MuiGrid-root {
    padding-left: 20px; } }

.user-notes,
.error-message {
  font-style: italic;
  color: #CE2424; }
  .user-notes strong,
  .error-message strong {
    color: #008cb6;
    font-style: normal;
    font-weight: bold; }

.relatives-manager {
  margin-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative; }

.new-relative-container {
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0;
  align-items: center; }
  .new-relative-container > .MuiGrid-item:last-child {
    margin-top: 20px; }
    @media screen and (min-width: 960px) {
      .new-relative-container > .MuiGrid-item:last-child {
        margin-top: 0;
        text-align: right; } }

.fields-group.fields-group--relative {
  padding-top: 75px;
  margin-top: 0;
  margin-bottom: 0; }
  @media screen and (min-width: 960px) {
    .fields-group.fields-group--relative {
      padding-top: 50px;
      padding-bottom: 10px; } }

fieldset.fields-group {
  background: #F0F6F7;
  padding: 35px 20px 30px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%; }
  fieldset.fields-group:not(.fields-group--relative):first-of-type {
    margin-bottom: 30px; }
  fieldset.fields-group div[class*="MuiGrid-spacing"] {
    margin-bottom: 0; }
  fieldset.fields-group .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.5); }
  fieldset.fields-group .Mui-disabled {
    opacity: .8; }
  fieldset.fields-group .quill-container__note {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    padding-left: 0; }
  fieldset.fields-group .quill {
    padding-left: 0;
    padding-right: 0; }

.relatives-manager h2,
fieldset.fields-group > legend {
  left: 0;
  color: #fff;
  background-image: url("assets/svg/label-bg.svg");
  font-weight: bold;
  font-size: 1.2rem;
  padding: .3em .8em;
  margin-left: 0 !important; }
  @media screen and (min-width: 776px) {
    .relatives-manager h2,
    fieldset.fields-group > legend {
      margin-left: calc(50% - 645px /2) !important; } }

.relatives-manager h2 {
  display: inline-block;
  position: relative;
  top: -22px;
  left: 20px; }
  @media screen and (min-width: 776px) {
    .relatives-manager h2 {
      left: 0; } }

.placeholder-helper__result {
  font-size: 1.2em;
  padding: 10px;
  background: rgba(0, 0, 0, 0.05); }

p.Mui-error {
  margin: 3px 0 0; }

.Mui-disabled {
  opacity: .5; }

.box-layout__submit {
  border-top: 1px solid #eff3f6;
  padding-top: 30px;
  justify-content: center; }
  .box-layout__submit button {
    min-width: 200px; }
  .box-layout__submit svg {
    margin-left: 8px; }

.row {
  max-width: 114rem;
  margin: 0 auto; }
  .row:not(:last-child) {
    margin-bottom: 8rem; }
    @media (max-width: 56.25em) {
      .row:not(:last-child) {
        margin-bottom: 6rem; } }
  @media (max-width: 56.25em) {
    .row {
      max-width: 50rem;
      padding: 0 3rem; } }
  .row::after {
    content: "";
    display: table;
    clear: both; }
  .row [class^="col-"] {
    float: left; }
    .row [class^="col-"]:not(:last-child) {
      margin-right: 1rem; }
      @media (max-width: 56.25em) {
        .row [class^="col-"]:not(:last-child) {
          margin-right: 0;
          margin-bottom: 3rem; } }
    @media (max-width: 56.25em) {
      .row [class^="col-"] {
        width: 100% !important; } }
  .row .col-1-of-1 {
    width: 100%; }
  .row .col-1-of-2 {
    width: calc((100% - 1rem) / 2); }
  .row .col-1-of-3 {
    width: calc((100% - 2 * 1rem) / 3); }
  .row .col-2-of-3 {
    width: calc( 2 * ((100% - 2 * 1rem) / 3) + 1rem); }
  .row .col-1-of-4 {
    width: calc((100% - 3 * 1rem) / 4); }
  .row .col-2-of-4 {
    width: calc( 2 * ((100% - 3 * 1rem) / 4) + 1rem); }
  .row .col-3-of-4 {
    width: calc( 3 * ((100% - 3 * 1rem) / 4) + (2 * 1rem)); }

[class*="MuiGrid-spacing-xs-24"] {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

input[type="text"]:focus:not([readonly]) + label {
  color: #008cb6 !important; }

.radio-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  align-content: center;
  padding: 0 1rem !important; }
  .radio-button-container label {
    font-size: 1rem; }

.radioGroup {
  display: inline !important;
  width: "100%"; }

.customAppBar {
  background-color: white !important;
  box-shadow: none !important;
  border-bottom: 1px solid #f5f5f5; }

.customTabs {
  width: 65% !important; }

.customTab {
  background-color: white !important;
  box-shadow: none !important;
  color: #313131; }

.topSectionButtons {
  display: flex; }

.PaperContainer {
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important; }

.PaperContainerCustomer {
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important; }

.react-tooltip-lite {
  background-color: #005468;
  border-radius: 5px;
  color: white;
  font-size: 0.8rem;
  text-align: center; }

.react-tooltip-lite-arrow {
  border-color: #005468; }

.actionTableContainer {
  display: flex;
  justify-content: flex-start; }

.txtLeft {
  text-align: left; }

.iconButton {
  margin-left: 5px !important;
  padding: 6px !important;
  border-radius: 8px !important; }

.none {
  display: none; }

.flex {
  display: flex; }

.buttonsActivityContainer {
  display: flex;
  margin-top: 1rem; }
  .buttonsActivityContainer button {
    margin-top: 1rem; }

.sortableStyle {
  list-style: none; }

.mr5 {
  margin-right: 5px !important; }

.mt15 {
  margin-top: 15px !important; }

.w200 {
  width: 200px; }

.w100 {
  width: 100px; }

.containerTableHead {
  display: inline-flex; }

.ql-container {
  height: auto !important;
  min-height: 200px; }

.ql-editor {
  height: auto !important;
  min-height: 200px; }

.label {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px 5px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  background-color: white; }
  .label button {
    height: 24px;
    width: 24px;
    text-indent: -9999px;
    overflow: hidden;
    position: relative; }
    .label button svg {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      top: 0;
      left: 0; }
    .label button.button--delete svg {
      fill: #CE2424; }

svg.label__drag {
  fill: rgba(0, 0, 0, 0.1);
  cursor: grab; }

.label__swatch {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 15px; }

.label__name {
  flex-grow: 1; }

.table_tasks {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
  background-color: white;
  margin-top: 20px; }
  .table_tasks th,
  .table_tasks td {
    border: 1px solid black;
    padding: 10px;
    min-width: 150px; }

.logoGoogle {
  border-radius: 7px 0px 0px 7px;
  background-color: white;
  margin-right: 20px;
  padding: 10px;
  margin-left: -5px; }

.logoAdd {
  margin-right: 20px;
  margin-left: 10px;
  width: 15%; }

.logoSave {
  margin-right: 10px;
  margin-left: 5px;
  width: 15%; }

.info-container {
  margin-bottom: 30px;
  width: 100%; }
  .info-container a {
    color: #008cb6;
    font-weight: bold; }
  .info-container__left-box, .info-container__right-box {
    padding: 20px;
    margin-bottom: 15px;
    box-sizing: border-box; }
  @media screen and (min-width: 800px) {
    .info-container {
      display: flex; }
      .info-container__left-box, .info-container__right-box {
        width: 49%; }
      .info-container__left-box {
        margin-right: 2%; }
      .info-container:after {
        content: '';
        display: block;
        clear: both; } }
  .info-container__head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px; }
  .info-container__description {
    font-size: 16px;
    color: #008CB6;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center; }
    .info-container__description svg {
      margin-right: 5px;
      fill: #008CB6; }
  .info-container__title {
    font-size: 22px;
    font-weight: bold;
    color: #313131; }
  .info-container__hr {
    height: 1px;
    background-color: #CBD6E2; }
  @media screen and (min-width: 600px) {
    .info-container__content {
      display: flex; } }
  .info-container__sub {
    display: flex;
    flex-direction: column;
    width: 50%;
    font-size: 16px;
    margin-bottom: 20px; }
    .info-container__sub:last-of-type {
      margin-bottom: 0; }
    @media screen and (min-width: 600px) {
      .info-container__sub {
        margin-bottom: 0; } }

.popoverStatusAndDueDate {
  display: flex;
  justify-content: space-between; }
  .popoverStatusAndDueDate__subcontainer {
    flex-direction: column; }

.tooltip > .popoverStatusAndDueDate {
  margin-top: 5px; }

.MuiGrid-container.form-maxWidth {
  max-width: 675px; }

.MuiTypography-root.form-maxWidth,
legend.form-maxWidth,
.quill-container.form-maxWidth,
.user-notes.form-maxWidth {
  max-width: 645px; }

@media screen and (min-width: 600px) {
  .form-maxWidth {
    margin-left: auto !important;
    margin-right: auto !important; } }

/*** SI DEFINISCONO IN QUESTO SCSS LE IMPOSTAZIONI DI TIPOGRAFIA BASE DI PROGETTO CHE POI VERRANNO RECUPERATE IN TUTTI GLI ALTRI FOGLI DI STILE (esempio: titoli) ***/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important; }

.cursPoint {
  cursor: pointer; }

.uppercase {
  text-transform: uppercase; }

a {
  text-decoration: none;
  cursor: pointer; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-weight: 400; }

h1 {
  font-size: 2.125rem;
  margin-bottom: 15px;
  color: #008cb6; }
  h1 svg,
  h1 .MuiSvgIcon-root {
    fill: #008cb6;
    margin-right: 10px;
    height: 36px;
    width: 36px;
    vertical-align: middle;
    margin-bottom: 5px; }

h2 {
  font-size: 1.3rem !important;
  font-weight: 700; }

h3.MuiTypography-body1 {
  margin-top: 20px;
  margin-bottom: 15px; }

h3,
h3.tab-form__title {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold; }

h3[class*="subtitle1"],
fieldset legend[class*="subtitle1"] {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 16px auto !important;
  padding: 0;
  line-height: 1.2em;
  color: #008cb6;
  background: none; }

fieldset legend[class*="subtitle1"] {
  top: 27px;
  position: relative;
  margin: 16px 0 !important; }

h4 {
  font-size: 2.125rem;
  margin: 25px 0 10px !important; }

h5 {
  font-size: 1.5rem; }

h6 {
  font-size: 1.1rem; }

/* Sizes */
.smlTxt {
  font-size: 0.75rem !important; }

.lrgTxt {
  font-size: 1.8rem; }

.mediumTxt {
  font-size: 0.9rem; }

.smallTit {
  padding: 0.3rem 0;
  text-transform: capitalize;
  font-size: 0.8rem; }

.dropDownTxt {
  font-size: 0.875rem; }

.colorWhite {
  color: #fff !important; }

.colorGreen {
  background-color: #008cb6  !important; }

.colorRed {
  background-color: #CE2424 !important; }

.MuiExpansionPanelDetails-root {
  display: block !important; }

.MuiExpansionPanel-root.single-relative {
  border-radius: 8px !important;
  margin-top: 16px; }
  .MuiExpansionPanel-root.single-relative:before {
    display: none; }
  .MuiExpansionPanel-root.single-relative .MuiExpansionPanelSummary-root {
    border-radius: 8px;
    color: #008cb6;
    font-weight: bold; }
    .MuiExpansionPanel-root.single-relative .MuiExpansionPanelSummary-root.Mui-expanded {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
  .MuiExpansionPanel-root.single-relative .button--outlined {
    margin-right: 10px; }

div.accordion-panel {
  border-radius: 8px !important;
  margin-bottom: 15px;
  clear: both; }
  div.accordion-panel:last-of-type {
    margin-bottom: 0; }
  div.accordion-panel:before {
    display: none; }
  div.accordion-panel .ReactTable {
    border: none;
    box-shadow: none !important; }
  div.accordion-panel div[role="button"] svg {
    fill: #008cb6; }

p.accordion-panel__title {
  font-weight: bold;
  font-size: 1.2rem; }

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5); }

.box-layout {
  background-size: cover;
  padding: 100px 15px 40px;
  background-color: #F0F6F7;
  position: relative;
  min-height: calc(100vh - 190px);
  overflow: auto;
  max-width: 1320px;
  margin: 0 auto; }
  .box-layout__progress {
    min-height: auto;
    height: calc(100vh - 340px); }
  @media (min-width: 112.5em) {
    .box-layout {
      padding: 120px 80px 0;
      min-height: calc(100vh - 170px); }
      .box-layout__progress {
        min-height: calc(100vh - 340px); } }

.box-layout--login {
  padding: 0;
  min-height: auto; }

.login__page-content {
  position: relative;
  height: calc(100vh - 50px); }
  .login__page-content .box-layout__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 40px 70px;
    text-align: center; }
    .login__page-content .box-layout__box .box-layout__logo {
      width: 200px;
      display: block;
      margin: 0 auto; }

.box-layout__box,
.ReactTable {
  background: rgba(255, 255, 255, 0.8);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  clear: both; }

.topSection {
  display: block;
  margin-bottom: 15px; }
  .topSection:after {
    content: '';
    display: table;
    clear: both; }
  .topSection h1,
  .topSection .box-layout__title {
    float: left;
    margin-top: 0; }
  .topSection .topButtons,
  .topSection .topSectionButtons,
  .topSection > button {
    float: right; }
  .topSection .topButtons button:first-of-type {
    margin-right: 15px; }
  @media (max-width: 56.25em) {
    .topSection .topButtons,
    .topSection .topSectionButtons,
    .topSection > button,
    .topSection h1,
    .topSection .box-layout__title {
      float: none; } }

.box-layout__box {
  padding: 40px 100px; }
  @media (max-width: 75em) {
    .box-layout__box {
      padding: 40px 15px; } }
  .box-layout__box--users {
    width: 85%;
    margin: 6rem auto;
    overflow-y: auto; }
    @media only screen and (max-width: 375px) {
      .box-layout__box--users {
        margin-top: 20rem !important; } }
    @media only screen and (min-width: 376px) and (max-width: 599px) {
      .box-layout__box--users {
        margin-top: 10rem !important; } }
  .box-layout__box--activity {
    overflow-y: auto; }
  .box-layout__box--courses {
    width: 95%;
    margin: 6rem auto;
    overflow-y: auto; }
    @media only screen and (max-width: 375px) {
      .box-layout__box--courses {
        margin-top: 20rem !important; } }
    @media only screen and (min-width: 376px) and (max-width: 599px) {
      .box-layout__box--courses {
        margin-top: 10rem !important; } }

.box-layout__title {
  margin: 0 0 1.6rem 0;
  line-height: 1; }

button,
.button,
button[type="button"],
span.button {
  cursor: pointer;
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: bold !important;
  background-color: #008cb6;
  border-color: #008cb6;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  font-size: 16px;
  height: 42px;
  line-height: 39px;
  color: white;
  padding: 0 20px;
  text-transform: none;
  transition: background-color .15s ease-in-out, color .15s ease-in-out; }
  button:hover, button:active, button:focus,
  .button:hover,
  .button:active,
  .button:focus,
  button[type="button"]:hover,
  button[type="button"]:active,
  button[type="button"]:focus,
  span.button:hover,
  span.button:active,
  span.button:focus {
    outline: none; }
  button:hover,
  .button:hover,
  button[type="button"]:hover,
  span.button:hover {
    background-color: #0099c6;
    border-color: #0099c6; }
  button:active,
  .button:active,
  button[type="button"]:active,
  span.button:active {
    background-color: #00afe2;
    border-color: #00afe2; }
  button.Mui-disabled:not(.button--confirm) svg,
  .button.Mui-disabled:not(.button--confirm) svg,
  button[type="button"].Mui-disabled:not(.button--confirm) svg,
  span.button.Mui-disabled:not(.button--confirm) svg {
    fill: #aaa !important; }

button.button,
span.button {
  text-transform: none;
  font-size: 1em; }
  button.button svg,
  span.button svg {
    margin: 0 8px;
    vertical-align: middle;
    position: relative;
    bottom: 1px;
    width: 22px;
    height: 22px;
    fill: white; }
  button.button--outlined,
  span.button--outlined {
    border-width: 2px !important;
    border-color: #008cb6;
    color: #008cb6;
    background: rgba(255, 255, 255, 0);
    box-shadow: none;
    border-radius: 8px; }
    button.button--outlined:hover, button.button--outlined:focus,
    span.button--outlined:hover,
    span.button--outlined:focus {
      background: rgba(255, 255, 255, 0); }
    button.button--outlined:hover,
    span.button--outlined:hover {
      color: #0099c6;
      box-shadow: none; }
    button.button--outlined:focus,
    span.button--outlined:focus {
      color: #00afe2;
      box-shadow: none; }
    button.button--outlined.colorRed,
    span.button--outlined.colorRed {
      background: rgba(255, 255, 255, 0);
      color: #CE2424;
      border: none; }
    button.button--outlined svg,
    span.button--outlined svg {
      fill: #008cb6; }
  button.button--login,
  span.button--login {
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 42px;
    width: 210px;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 2px 7px;
    align-items: flex-start; }
  button.button--confirm,
  span.button--confirm {
    background-color: #4caf50;
    border-color: #4caf50; }
    button.button--confirm:hover,
    span.button--confirm:hover {
      background-color: #429b45;
      border-color: #429b45; }
    button.button--confirm:focus,
    span.button--confirm:focus {
      background-color: #3c8d3f;
      border-color: #3c8d3f; }
  button.button--cancel,
  span.button--cancel {
    background-color: #CE2424;
    border-color: #CE2424; }
    button.button--cancel:hover,
    span.button--cancel:hover {
      background-color: #b82121;
      border-color: #b82121; }
    button.button--cancel:focus,
    span.button--cancel:focus {
      background-color: #a51f1f;
      border-color: #a51f1f; }

.actions-group {
  text-align: right; }
  .actions-group .action {
    background: rgba(255, 255, 255, 0);
    color: #008cb6;
    box-shadow: none;
    text-transform: none;
    font-weight: bold;
    border: none;
    padding: 0;
    display: inline-block;
    text-align: initial; }
    .actions-group .action:hover, .actions-group .action:focus {
      background: rgba(255, 255, 255, 0); }
  .actions-group--new .action:first-of-type {
    margin-right: 20px; }
  .actions-group--new .action svg {
    vertical-align: middle;
    position: relative; }
  .actions-group--edit .action {
    position: relative;
    width: 40px;
    height: 40px;
    text-indent: -999px;
    overflow: hidden; }
    .actions-group--edit .action--delete svg {
      fill: #CE2424; }
  .actions-group--edit svg {
    position: absolute;
    right: 0;
    top: 10px;
    width: 25px;
    height: 25px;
    line-height: 30px;
    text-align: center;
    text-indent: 0; }

.MuiButtonBase-root.button--delete-element {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: center;
  cursor: pointer;
  color: #CE2424;
  font-weight: 700;
  background: none;
  border: none; }
  .MuiButtonBase-root.button--delete-element svg {
    fill: #999;
    vertical-align: middle;
    fill: #CE2424;
    height: 20px;
    width: 20px;
    margin-right: 3px !important;
    position: relative; }
  .MuiButtonBase-root.button--delete-element:hover, .MuiButtonBase-root.button--delete-element:focus {
    background: none;
    text-decoration: underline; }

.button--confirm.Mui-disabled {
  background: #aaa;
  border-color: #aaa;
  color: #fff !important; }

.MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot {
  width: auto; }
  .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot .MuiPickersYear-root.MuiTypography-subtitle1 {
    color: #313131; }
  .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot button {
    background-color: inherit;
    color: inherit;
    border: none;
    padding: 0;
    font-size: inherit;
    height: auto; }
    .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot button.MuiPickersDay-day {
      width: 36px;
      height: 36px;
      border-radius: 50%; }
      .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot button.MuiPickersDay-day.MuiPickersDay-current {
        background-color: rgba(28, 136, 191, 0.2); }
      .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot button.MuiPickersDay-day.MuiPickersDay-daySelected {
        background-color: #008cb6;
        color: #fff; }
  .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot h4 {
    margin: 0 !important; }
  .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot .MuiPickersCalendarHeader-switchHeader button {
    padding: 12px !important; }
  .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot .MuiDialogActions-spacing button {
    text-transform: uppercase;
    font-family: 0.875rem;
    color: #008cb6 !important; }
  .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot .MuiInputAdornment-positionEnd > button {
    padding: 0;
    background-color: inherit;
    border: none; }
    .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot .MuiInputAdornment-positionEnd > button svg {
      fill: #008cb6; }
    .MuiDialog-paperScrollPaper.MuiPickersModal-dialogRoot .MuiInputAdornment-positionEnd > button:hover {
      background-color: inherit; }

.drawer__toggle {
  width: 40px;
  height: 59px;
  float: right;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  margin-right: 20px; }
  .drawer__toggle svg {
    fill: #fff;
    height: 100%;
    width: 30px; }
  .drawer__toggle div {
    width: 90%;
    height: 3px;
    margin-top: 5px;
    background-color: white; }
  @media (min-width: 901px) {
    .drawer__toggle {
      display: none; } }

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 83, 104, 0.1);
  width: 100%;
  height: 50px; }
  .footer span {
    font-size: 14px; }
    .footer span b {
      font-weight: 800; }

.header {
  position: fixed;
  top: 0;
  background-color: #008cb6;
  background-position: center;
  background-size: cover;
  width: 100%;
  z-index: 100; }
  .header__logo {
    display: block;
    margin: 1rem 0 1rem 65px;
    width: 10rem; }
    @media screen and (max-width: 900px) {
      .header__logo {
        width: 120px; } }
    @media screen and (max-width: 1799px) {
      .header__logo {
        margin-left: 20px; } }
  .header__nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .header__nav--list {
      display: flex;
      flex-direction: row;
      align-items: center;
      list-style: none;
      margin: 0;
      font-size: 1rem; }
      @media screen and (max-width: 900px) {
        .header__nav--list {
          display: none; } }
      @media screen and (min-width: 900px) and (max-width: 1200px) {
        .header__nav--list {
          font-size: 0.8rem; } }
      .header__nav--list li {
        color: white;
        text-transform: uppercase; }
        .header__nav--list li a {
          color: white;
          padding: 0 15px;
          height: 69px;
          line-height: 69px;
          display: block; }
          .header__nav--list li a:hover {
            cursor: pointer;
            color: white;
            opacity: 0.8; }
  .header .active {
    font-weight: 700;
    background-color: rgba(0, 83, 104, 0.3); }

.header__divider {
  height: 45px;
  padding: .5px;
  background-color: rgba(255, 255, 255, 0.3); }

.header__right {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: white;
  margin-right: 65px; }
  @media screen and (max-width: 1799px) {
    .header__right {
      margin-right: 20px; } }

.header__user {
  margin: 0 0 0 15px; }
  .header__user > a {
    height: 100%;
    padding-right: 0; }
  .header__user .MuiListItemAvatar-root {
    height: 100%;
    min-width: auto;
    display: flex;
    align-items: center;
    background: none !important;
    border: none !important; }
  .header__user .MuiAvatar-circle {
    min-width: auto;
    border-radius: 50%;
    border: 1px solid white; }
    @media (max-width: 64em) {
      .header__user .MuiAvatar-circle {
        width: 30px;
        height: 30px; } }

.header__name,
.header__logout {
  line-height: normal; }

.header__name {
  font-weight: bold; }
  @media (max-width: 64em) {
    .header__name {
      display: none; } }

.header__logout {
  cursor: pointer;
  text-decoration: underline; }

[class*='MuiDialog-paper'] {
  width: 80%; }
  [class*='MuiDialog-paper'] .dialogContent {
    padding-top: 5px; }
  [class*='MuiDialog-paper'] [class*='MuiFormControl-root'] {
    margin-bottom: 20px; }
    [class*='MuiDialog-paper'] [class*='MuiFormControl-root']:last-of-type {
      margin-bottom: 0; }
  [class*='MuiDialog-paper'] [class*='MuiDialogActions-root'] {
    margin: 0;
    padding: 0 24px 20px; }
  [class*='MuiDialog-paper'] .button.button svg {
    margin-right: 0 !important;
    width: auto;
    position: relative;
    top: 1px; }

ul[role="listbox"] {
  padding: 0; }
  ul[role="listbox"] button.iconButton {
    background: rgba(255, 255, 255, 0) !important;
    border: none;
    padding: 0 !important;
    margin: 0 !important;
    transition: color .2s ease-in-out; }
    ul[role="listbox"] button.iconButton svg {
      fill: #ce2424; }
    ul[role="listbox"] button.iconButton:hover, ul[role="listbox"] button.iconButton:focus {
      background: rgba(255, 255, 255, 0) !important;
      border: none; }
      ul[role="listbox"] button.iconButton:hover svg, ul[role="listbox"] button.iconButton:focus svg {
        fill: #a61c1c; }

.dialog--visible-overflow .MuiDialog-paper,
.dialog--visible-overflow .dialogContent {
  overflow: visible; }

.dialog--visible-overflow .react-select + div[class*="menu"] {
  top: 56px;
  z-index: 99; }

.button_pagination {
  display: flex;
  justify-content: center;
  align-items: baseline; }
  .button_pagination svg {
    position: relative;
    top: 2px; }
  .button_pagination .span-left {
    margin-left: 10px; }
  .button_pagination .span-right {
    margin-right: 10px; }

.pagination-bottom {
  display: flex;
  justify-content: center;
  background-color: rgba(1, 113, 145, 0.02);
  font-size: .9rem;
  border-radius: 0 0 8px 8px; }
  .pagination-bottom .-pagination {
    width: 100%;
    background: transparent !important;
    box-shadow: none !important;
    border-top: 2px solid rgba(0, 0, 0, 0.16); }
    .pagination-bottom .-pagination input,
    .pagination-bottom .-pagination select {
      border-radius: 0 !important;
      border: none !important;
      background: none !important; }
    .pagination-bottom .-pagination input {
      width: 50px !important; }
  .pagination-bottom .-previous button {
    box-shadow: none !important;
    background-color: transparent !important;
    color: #008cb6 !important; }
  .pagination-bottom .-next button {
    box-shadow: none !important;
    background-color: transparent !important;
    color: #008cb6 !important;
    float: right; }
  @media screen and (max-width: 600px) {
    .pagination-bottom {
      font-size: .8rem; }
      .pagination-bottom button {
        width: 40px !important;
        text-align: center; }
      .pagination-bottom span[class^='span'] {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        word-wrap: normal !important; }
      .pagination-bottom .-pageJump {
        width: 72px; }
      .pagination-bottom span.select-wrap select {
        padding: 4px 10px;
        border-radius: 3px; } }
  @media screen and (min-width: 600px) and (max-width: 800px) {
    .pagination-bottom {
      font-size: .9rem;
      flex-direction: row; }
      .pagination-bottom .-previous,
      .pagination-bottom .-next {
        flex: 0 0 !important; } }

@media screen and (max-width: 600px) {
  .ReactTable .pagination-bottom .-pagination {
    padding: 10px 0; } }

.container-row {
  display: flex; }

.balls-cell {
  display: flex; }

.balls-container {
  margin-right: 25px;
  width: 220px; }
  .balls-container:last-of-type {
    margin-right: 0; }
  .balls-container__title {
    text-transform: uppercase;
    color: #676767;
    font-size: 12px;
    font-weight: 600; }

.circle {
  display: block;
  background: #B5B5B5;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  margin: 0 5px 0 0; }

.balls:after {
  content: '';
  display: block;
  clear: both;
  margin-bottom: 3px; }

.balls > div {
  float: left;
  margin-bottom: 3px; }
  .balls > div:nth-of-type(9n + 9) {
    clear: left; }

.balls .circle {
  transition: opacity .2s ease-in-out;
  cursor: pointer; }
  .balls .circle:hover {
    opacity: .7; }

.tooltip {
  display: flex;
  flex-direction: column;
  width: 242px;
  height: 95px;
  background: white;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  padding: 15px 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.38); }
  .tooltip label {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 23px;
    margin-top: 5px; }
    .tooltip label:first-of-type {
      margin-top: 0; }
  .tooltip a {
    text-decoration: underline;
    font-weight: bold;
    color: #008cb6;
    line-height: 1.2em; }
  .tooltip .circle {
    width: 13px;
    height: 13px; }
  .tooltip__row {
    display: flex;
    align-items: center; }

.quill-container {
  width: 100%; }

.quill-container .quill-container__note {
  display: block;
  margin-bottom: 20px;
  background: #F0F6F7;
  margin-bottom: 0;
  border-radius: 8px 8px 0 0;
  padding: 20px 30px 0; }
  @media screen and (min-width: 900px) {
    .quill-container .quill-container__note {
      background-color: rgba(255, 255, 255, 0);
      margin: 0 0 -45px 30px;
      padding: 0; } }

.quill {
  background: #F0F6F7;
  padding: 15px 20px 30px;
  border-radius: 0 0 8px 8px; }
  .quill .ql-toolbar.ql-snow {
    border: none;
    text-align: right;
    padding: 0 0 10px; }
  .quill .ql-container.ql-snow {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important; }
  .quill span.ql-formats:last-of-type {
    margin-right: 0; }
  @media screen and (min-width: 900px) {
    .quill {
      border-radius: 8px; } }

[class*="MuiSnackbar"] {
  padding: 6px 16px; }
  [class*="MuiSnackbar"] button,
  [class*="MuiSnackbar"] button:hover {
    border-color: rgba(255, 255, 255, 0);
    border-radius: 50%;
    padding: 0; }

.sidedrawer {
  position: fixed;
  max-width: 70%;
  width: 280px;
  height: calc(100% - 59px);
  right: 0;
  top: 59px;
  padding: 0 20px 30px;
  z-index: 200;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  font-size: 1rem;
  background-color: white;
  box-shadow: -1px 5px 4px rgba(0, 0, 0, 0.2); }
  .sidedrawer ul {
    margin: 0; }
  @media (min-width: 900px) {
    .sidedrawer {
      display: none; } }
  .sidedrawer__logo {
    width: 70%;
    margin: 0 auto; }
    .sidedrawer__logo__img {
      width: 100%; }
  .sidedrawer__Open {
    transform: translateX(0); }
  .sidedrawer__Close {
    transform: translateX(100%); }
  .sidedrawer__nav {
    margin-top: 4rem; }
  .sidedrawer__main__item {
    list-style: none;
    border-bottom: 1px solid #f0f0f0;
    margin: 0; }
    .sidedrawer__main__item:last-of-type {
      border-bottom: none; }
  .sidedrawer__main__link {
    display: block;
    padding: 15px 0; }
    .sidedrawer__main__link:link, .sidedrawer__main__link:visited {
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 800;
      color: #364051; }
    .sidedrawer__main__link:hover, .sidedrawer__main__link:active {
      color: #005468; }
  .sidedrawer__logout {
    text-transform: uppercase;
    font-weight: 800;
    color: #666;
    padding: 10px 0;
    display: block; }

.tasks-sortable {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%; }

.course-template__group {
  background-color: #eff3f6;
  padding: 20px 20px 30px;
  margin-top: 30px;
  border-radius: 8px;
  position: relative; }
  .course-template__group h2 {
    margin-top: 12px;
    padding-right: 120px; }

div.tasks__heading {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  width: 100%; }
  div.tasks__heading > div {
    padding: 14px 0; }
  @media screen and (max-width: 900px) {
    div.tasks__heading {
      display: none; } }

.tasks__list {
  margin: 0;
  width: 100%; }

.tasks__heading,
.task {
  width: 100%;
  display: grid;
  grid-template-columns: 100px 6fr 4fr 165px 200px 6fr 4fr;
  font-size: 1rem; }

.task-references__heading,
.task--reference {
  grid-template-columns: 2fr 8fr 4fr 4fr 2.5fr 2.5fr; }

.task {
  font-size: 1rem;
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  margin-top: -1px;
  padding: 14px 14px 14px 0;
  width: 100%;
  margin: 10px 0;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  box-sizing: border-box; }
  .task p {
    margin: 0; }
  .task .task__label {
    display: none; }
  .task:first-of-type {
    margin-top: 0; }
  .task > div {
    padding: 0 10px; }
  .task .task__handle {
    font-size: 1.5rem;
    color: #ccc;
    cursor: grab;
    line-height: 1rem; }
  .task__name, .task__type, .task__group, .task__heading, .task__notes {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .task__due-date svg, .task__status svg, .task__owner svg {
    vertical-align: middle;
    font-size: 1rem;
    fill: #008cb6;
    padding: 5px;
    cursor: pointer; }
  .task button.iconButton {
    background: rgba(255, 255, 255, 0) !important;
    border: none;
    padding: 0 !important;
    margin: 0 5px 0 0 !important;
    transition: color .2s ease-in-out;
    height: auto;
    line-height: normal;
    justify-content: start; }
    .task button.iconButton svg {
      fill: #008cb6; }
    .task button.iconButton.button--cancel svg {
      fill: #ce2424; }
    .task button.iconButton.button--cancel:hover, .task button.iconButton.button--cancel:focus {
      background: rgba(255, 255, 255, 0) !important;
      border: none; }
      .task button.iconButton.button--cancel:hover svg, .task button.iconButton.button--cancel:focus svg {
        fill: #a61c1c; }
  @media screen and (max-width: 900px) {
    .task {
      display: block;
      padding: 14px 50px 14px 14px; }
      .task button.iconButton {
        margin-right: 0 !important; }
      .task > div {
        padding: 3px 5px; }
      .task__type, .task__due-date, .task__status, .task__owner {
        background-color: #f9f9f9;
        font-size: .9rem; }
        .task__type .task__value, .task__due-date .task__value, .task__status .task__value, .task__owner .task__value {
          display: block;
          font-weight: bold; }
      .task__type, .task__due-date, .task__status, .task__owner {
        box-sizing: border-box;
        margin-bottom: 5px;
        position: relative; }
        .task__type .status__name svg,
        .task__type .task__value svg,
        .task__type .datetime svg, .task__due-date .status__name svg,
        .task__due-date .task__value svg,
        .task__due-date .datetime svg, .task__status .status__name svg,
        .task__status .task__value svg,
        .task__status .datetime svg, .task__owner .status__name svg,
        .task__owner .task__value svg,
        .task__owner .datetime svg {
          position: absolute;
          top: 0;
          right: 0; }
      .task:after {
        content: '';
        display: block;
        clear: both; }
      .task .task__actions,
      .task .task__handle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .task .task__handle {
        left: 14px; }
      .task .task__actions {
        right: 14px; }
        .task .task__actions button {
          display: block;
          margin-right: 0; }
      .task__name, .task__owner, .task__status {
        clear: both; }
      .task__type, .task__due-date {
        width: 49%;
        float: left;
        min-height: 50px;
        font-size: .85rem; }
      .task__type {
        margin-right: 2%;
        clear: left; }
      .task__status .status {
        font-size: .85rem; }
      .task__notes {
        clear: both;
        display: block; }
        .task__notes p {
          border-top: 1px solid #eee;
          padding: 10px 0 0 0;
          margin-top: 10px;
          font-size: .85rem; }
      .task .task__label {
        display: inline;
        padding-right: 3px; }
      .task.task--reference {
        padding: 14px 40px; } }

.react-swipeable-view-container > div {
  overflow-x: hidden !important; }

.box-layout .tabs {
  overflow: visible; }
  .box-layout .tabs button.tab__button {
    background-color: rgba(255, 255, 255, 0);
    color: #008cb6;
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    border-radius: 0;
    border: none; }
  .box-layout .tabs [class^="MuiTabs-flexContainer"],
  .box-layout .tabs [class^="MuiTabs-root"],
  .box-layout .tabs button.tab__button {
    overflow: visible; }
  .box-layout .tabs [class^="MuiTabs-scroller"] {
    overflow: visible !important; }
  .box-layout .tabs button[class*="selected"]:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid rgba(255, 255, 255, 0);
    border-right: 8px solid rgba(255, 255, 255, 0);
    border-top: 8px solid #008cb6;
    bottom: -8px;
    z-index: 1; }

header.tabs__header {
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  margin-top: -40px; }

.react-swipeable-view-container .ReactTable {
  box-shadow: none !important;
  margin-bottom: 30px; }
  @media screen and (min-width: 960px) {
    .react-swipeable-view-container .ReactTable {
      margin-bottom: 0; } }

.react-swipeable-view-container > div > .MuiTypography-root {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.ReactTable .rt-thead .rt-resizable-header:after {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  width: 2px;
  top: 5px;
  bottom: 5px;
  right: 0; }

.ReactTable .rt-thead .rt-resizable-header:last-of-type:after {
  display: none; }

body > tr {
  font-size: 1rem;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15); }
  body > tr .actionTableContainer button {
    height: auto; }
    body > tr .actionTableContainer button.colorRed {
      border-color: #CE2424; }
  body > tr td {
    padding: 10px; }
    body > tr td:nth-of-type(1) {
      width: 50px; }
    body > tr td:nth-of-type(2) {
      width: 200px; }
    body > tr td:nth-of-type(3) {
      width: 150px; }
    body > tr td:nth-of-type(4) {
      width: 150px; }
    body > tr td:nth-of-type(5) {
      width: 100px; }
    body > tr td:nth-of-type(6) {
      width: 100px; }

.rt-thead.-header {
  background-color: #008cb6;
  border-radius: 8px 8px 0 0; }
  .rt-thead.-header .rt-th > div {
    color: #fff; }

thead tr > th:nth-of-type(1) {
  width: 50px; }

thead tr > th:nth-of-type(2) {
  width: 200px; }

thead tr > th:nth-of-type(3) {
  width: 150px; }

thead tr > th:nth-of-type(4) {
  width: 150px; }

thead tr > th:nth-of-type(5) {
  width: 100px; }

thead tr > th:nth-of-type(6) {
  width: 100px; }

.table_tasks thead {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); }

.table_tasks tr {
  font-size: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .table_tasks tr th {
    text-transform: uppercase;
    text-align: left; }
  .table_tasks tr th, .table_tasks tr td {
    border: none; }

.table_tasks .rt-tr,
.react-table .rt-tr {
  background: rgba(255, 255, 255, 0) !important; }

.table_tasks button,
.react-table button {
  height: auto; }
  .table_tasks button.colorRed,
  .react-table button.colorRed {
    border-color: #CE2424; }

.react-table {
  font-weight: 400;
  font-size: 1rem;
  width: 100%;
  border-radius: 8px !important; }
  .react-table input[type="text"],
  .react-table input[type="number"] {
    height: auto;
    margin: 0; }
  .react-table select {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: auto;
    display: flex; }
  .react-table .rt-thead .rt-th {
    text-align: left; }
    .react-table .rt-thead .rt-th svg path {
      fill: rgba(255, 255, 255, 0.7); }
    .react-table .rt-thead .rt-th.-sort-asc {
      box-shadow: inset 0 3px 0 0 white !important; }
      .react-table .rt-thead .rt-th.-sort-asc svg:first-of-type path {
        fill: white; }
    .react-table .rt-thead .rt-th.-sort-desc {
      box-shadow: inset 0 -3px 0 0 white !important; }
      .react-table .rt-thead .rt-th.-sort-desc svg:last-of-type path {
        fill: white; }

.table-filter {
  border: 1px solid #adadad !important;
  border-radius: 8px !important;
  width: 100%; }

.ReactTable {
  border-radius: 8px !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important; }
  .ReactTable .rt-thead.-filters .rt-th {
    border-right: none !important; }
  .ReactTable .rt-thead.-header {
    border-bottom: 2px solid #017191 !important;
    box-shadow: none !important; }
  .ReactTable .rt-thead .rt-th {
    border-right: none !important;
    text-transform: uppercase;
    color: #313131;
    font-size: 14px;
    font-weight: 600;
    padding: 14px 10px !important; }
  .ReactTable .rt-thead.-filters select {
    border: 1px solid #adadad !important;
    border-radius: 8px !important;
    width: 100%; }
  .ReactTable .rt-tbody .rt-td {
    border-right: none !important;
    padding: 7px 10px;
    line-height: 31px;
    position: relative;
    display: flex;
    align-items: center; }
    .ReactTable .rt-tbody .rt-td:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 10px;
      background-image: linear-gradient(to left, white, rgba(255, 255, 255, 0)); }

.tableSize {
  width: 96rem; }
  @media (max-width: 75em) {
    .tableSize {
      width: initial; } }

.table-header {
  display: flex;
  align-items: center; }
  .table-header span {
    margin-right: 5px; }

.svg-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center; }
  .svg-container svg {
    margin: 1px 0px; }

.task__due-date .MuiInput-underline:before,
.task__due-date .MuiInput-underline:after, .task__status .MuiInput-underline:before,
.task__status .MuiInput-underline:after, .task__owner .MuiInput-underline:before,
.task__owner .MuiInput-underline:after {
  display: none; }

.task__due-date .MuiTextField-root input {
  font-size: .9em;
  background-color: #F1F6FA;
  border: 1px solid #ddd;
  padding: 3px; }

.task__status__name,
.task__status .react-select, .task__owner__name,
.task__owner .react-select {
  display: inline-block;
  padding: 3px;
  border: 1px solid;
  border-color: transparent; }

.task__status .react-select, .task__owner .react-select {
  background-color: #F1F6FA;
  border-color: #ddd;
  max-width: 100%; }
  .task__status .react-select > div,
  .task__status .react-select div[class*="singleValue"], .task__owner .react-select > div,
  .task__owner .react-select div[class*="singleValue"] {
    margin: 0; }
  .task__status .react-select__control, .task__owner .react-select__control {
    vertical-align: text-top; }

.task__status .react-select__value-container, .task__owner .react-select__value-container {
  font-size: .9rem; }

.task__status .react-select__indicator,
.task__status .react-select__value-container,
.task__status .MuiInput-input,
.task__status .MuiInput-input svg, .task__owner .react-select__indicator,
.task__owner .react-select__value-container,
.task__owner .MuiInput-input,
.task__owner .MuiInput-input svg {
  padding: 0; }

.task__status .react-select__indicator svg, .task__owner .react-select__indicator svg {
  width: 16px; }

.task__status .react-select__indicator-separator, .task__owner .react-select__indicator-separator {
  display: none; }

.task__status .MuiInput-input, .task__owner .MuiInput-input {
  display: flex; }

.task__status__name,
.task__status .react-select {
  width: 100px; }

.task__owner__name,
.task__owner .react-select {
  width: 200px; }

.status {
  font-size: .9rem; }
  .status__color {
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    width: 10px;
    height: 10px; }
  .status-list .status:after {
    content: "/";
    color: #ddd;
    margin: 0 5px;
    position: relative;
    bottom: 1px; }
  .status-list .status:last-child:after {
    content: ""; }

div.color-choice {
  margin: 25px 0;
  width: 100%; }
  div.color-choice div.color-picker {
    padding: 0; }
    div.color-choice div.color-picker .circle-picker {
      width: 100% !important;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
      display: block !important; }
      div.color-choice div.color-picker .circle-picker > span {
        float: left; }

h3.tab-form__title {
  margin-bottom: 10px; }

.tab-form__label {
  display: block;
  margin: 20px 0 10px;
  margin-bottom: 10px; }

.tab-form__submit {
  width: 100%;
  margin-top: 25px; }
